import {gsap} from 'gsap';
import { map, lerp, clamp, getMousePos } from './utils';
// const images = Object.entries(require('../img/work/*.jpg'));
const images = [require('../img/work-1.jpg'), require('../img/work-2.jpg'), require('../img/work-3.jpg'), require('../img/work-4.jpg'), require('../img/work-5.jpg'), require('../img/work-6.jpg')];


// track the mouse position
let mousepos = {x: 0, y: 0};
// cache the mouse position
let mousePosCache = mousepos;
let cursorDirection = {x: mousePosCache.x-mousepos.x, y: mousePosCache.y-mousepos.y};

// update mouse position when moving the mouse
window.addEventListener('mousemove', ev => mousepos = getMousePos(ev));

export default class MenuItem {
    constructor(el, inMenuPosition, animatableProperties) {
        // el is the <a> with class "menu__item"
        this.DOM = {el: el};
        // position in the Menu
        this.inMenuPosition = inMenuPosition;
        // menu item properties that will animate as we move the mouse around the menu
        this.animatableProperties = animatableProperties;
        // the item text
        this.DOM.textInner = this.DOM.el.querySelector('.menu__item-textinner');
        // create the image structure
        this.layout();
        // initialize some events
        this.initEvents();
    }
    // create the image structure
    // we want to add/append to the menu item the following html:
    // <div class="hover-reveal">
    //   <div class="hover-reveal__inner" style="overflow: hidden;">
    //     <div class="hover-reveal__img" style="background-image: url(pathToImage);">
    //     </div>
    //   </div>
    // </div>
    layout() {
        // this is the element that gets its position animated (and perhaps other properties like the rotation etc..)
        this.DOM.reveal = this.DOM.el.querySelector('.hover-reveal');
        this.DOM.revealInner = this.DOM.el.querySelector('.hover-reveal__inner');
        this.DOM.revealImage = this.DOM.el.querySelector('.hover-reveal__img');

        // this.DOM.reveal = document.createElement('div');
        // this.DOM.reveal.className = 'hover-reveal';
        // this.DOM.reveal.style.transformOrigin = '0% 0%';
        // // the next two elements could actually be only one, the image element
        // // adding an extra wrapper (revealInner) around the image element with overflow hidden, gives us the possibility to scale the image inside
        // this.DOM.revealInner = document.createElement('div');
        // this.DOM.revealInner.className = 'hover-reveal__inner';
        // this.DOM.revealImage = document.createElement('div');
        // this.DOM.revealImage.className = 'hover-reveal__img';
        // this.DOM.revealImage.style.backgroundImage = `url(${images[this.inMenuPosition]})`;
        //
        // this.DOM.revealInner.appendChild(this.DOM.revealImage);
        // this.DOM.reveal.appendChild(this.DOM.revealInner);
        // this.DOM.el.appendChild(this.DOM.reveal);
    }
    // calculate the position/size of both the menu item and reveal element
    calcBounds() {
        this.bounds = {
          el: this.DOM.el.getBoundingClientRect(),
          reveal: this.DOM.reveal.getBoundingClientRect(),
          width: this.DOM.reveal.offsetWidth,
          height: this.DOM.reveal.offsetHeight
        };
    }
    // bind some events
    initEvents() {
        this.mouseenterFn = (ev) => {
            // show the image element
            this.showImage();
            this.firstRAFCycle = true;
            // start the render loop animation (rAF)
            this.loopRender();
        };
        this.mouseleaveFn = () => {
            // stop the render loop animation (rAF)
            this.stopRendering();
            // hide the image element
            this.hideImage();
        };

        this.DOM.el.addEventListener('mouseenter', this.mouseenterFn);
        this.DOM.el.addEventListener('mouseleave', this.mouseleaveFn);
    }
    // show the image element
    showImage() {
      if (this.tl) {
          this.tl.kill();
      }

      this.tl = gsap.timeline({
          onStart: () => {
              // show both image and its parent element
              gsap.set([this.DOM.reveal, this.DOM.revealInner], {opacity: 1})
              // set a high z-index value so image appears on top of other elements
              gsap.set(this.DOM.el, {zIndex: images.length});
          }
      })
      // animate the image wrap
      .to(this.DOM.revealInner, {
          duration: 1.3,
          ease: 'expo',
          startAt: {scale: 0.5},
          scale: 1
      })
      // animate the image element
      .to(this.DOM.revealImage, {
          duration: 1.3,
          ease: 'expo',
          startAt: {scaleX: 2},
          scaleX: 1
      }, 0)
      .to(this.DOM.reveal, {
          duration: 0.6,
          ease: 'power1.inOut'
      }, 0);
    }
    // hide the image element
    hideImage() {
      if (this.tl) {
          this.tl.kill();
      }

      this.tl = gsap.timeline({
          defaults: {
              duration: 1.2,
              ease: 'power1',
          },
          onStart: () => {
              gsap.set(this.DOM.el, {zIndex: 1});
          },
          onComplete: () => {
              gsap.set(this.DOM.reveal, {opacity: 0});
          }
      })
      .to(this.DOM.revealInner, {
          opacity: 0
      })
      .to(this.DOM.revealImage, {
          scaleX: 1.7
      }, 0)
      .to(this.DOM.reveal, {
          rotation: cursorDirection.x < 0 ? '+=5' : '-=5',
          y: '200%'
      }, 0);
    }
    // start the render loop animation (rAF)
    loopRender() {
        if ( !this.requestId ) {
            this.requestId = requestAnimationFrame(() => this.render());
        }
    }
    // stop the render loop animation (rAF)
    stopRendering() {
        if ( this.requestId ) {
            window.cancelAnimationFrame(this.requestId);
            this.requestId = undefined;
        }
    }
    // translate the item as the mouse moves
    render() {
        this.requestId = undefined;
        // calculate position/sizes the first time
        if ( this.firstRAFCycle ) {
            this.calcBounds();
        }

        // calculate the mouse distance (current vs previous cycle)
        const mouseDistanceX = clamp(Math.abs(mousePosCache.x - mousepos.x), 0, 100);
        // direction where the mouse is moving
        cursorDirection = {x: mousePosCache.x-mousepos.x, y: mousePosCache.y-mousepos.y};
        // updated cache values
        mousePosCache = {x: mousepos.x, y: mousepos.y};

        // new translation values
        // the center of the image element is positioned where the mouse is
        this.animatableProperties.tx.current = this.isPositionOdd ? Math.abs(mousepos.x - this.bounds.el.left) : Math.abs(mousepos.x - this.bounds.el.left) - this.bounds.width;
        this.animatableProperties.ty.current = this.firstRAFCycle ? this.bounds.height/1.5 : Math.abs(mousepos.y - this.bounds.el.top);
        // new rotation value
        let startingAngle = -30;
        this.animatableProperties.rotation.current = this.firstRAFCycle
                                                     ? startingAngle
                                                     : map(mouseDistanceX, 0, 300, startingAngle, cursorDirection.x < 0 ? startingAngle+100 : startingAngle-100)


        // set up the interpolated values
        // for the first cycle, both the interpolated values need to be the same so there's no "lerped" animation between the previous and current state
        this.animatableProperties.tx.previous = this.firstRAFCycle ? this.animatableProperties.tx.current : lerp(this.animatableProperties.tx.previous, this.animatableProperties.tx.current, this.animatableProperties.tx.amt);
        this.animatableProperties.ty.previous = this.firstRAFCycle ? this.animatableProperties.ty.current : lerp(this.animatableProperties.ty.previous, this.animatableProperties.ty.current, this.animatableProperties.ty.amt);
        this.animatableProperties.rotation.previous = this.firstRAFCycle ? this.animatableProperties.rotation.current : lerp(this.animatableProperties.rotation.previous, this.animatableProperties.rotation.current, this.animatableProperties.rotation.amt);

        // set styles
        gsap.set(this.DOM.reveal, {
            x: this.animatableProperties.tx.previous,
            y: this.animatableProperties.ty.previous,
            rotation: this.animatableProperties.rotation.previous,
        });

        // loop
        this.firstRAFCycle = false;
        this.loopRender();
    }
}
