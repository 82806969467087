import { preloadImages } from './utils';
import Lenis from '@studio-freight/lenis'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from "gsap/SplitText";
import TextOnPath from "./textOnPath";
import { Cursor } from './cursor';
import Menu from './menu';
import {preloader} from './preloader';

gsap.registerPlugin(ScrollTrigger, SplitText);

const DOM = {
    sections: {
        columns: document.querySelector('.section--columns'),
        showcase: document.querySelector('.section--showcase'),
        archive: document.querySelector('.section--archive'),
    },
    columns: document.querySelectorAll('.section--columns > .columns'),
    columnWraps: document.querySelectorAll('.section--columns .column-wrap'),
    itemsWrappers: document.querySelectorAll('.section--columns .column-wrap .column'),
    items: document.querySelectorAll('.section--columns .column__item'),
    images: document.querySelectorAll('.section--columns .column__item-img'),
};

const menuEl = document.querySelector('.menu');

// Lenis smooth scrolling
let lenis;

// Initialize Lenis smooth scrolling
const initSmoothScrolling = () => {
	lenis = new Lenis({
		lerp: 1,
		smooth: true,
	});
	const scrollFn = (time) => {
		lenis.raf(time);
		requestAnimationFrame(scrollFn);
	};
	requestAnimationFrame(scrollFn);
};


const heroAnimation = () => {
  var tl = gsap.timeline(),
    mySplitText = new SplitText("#hero-quote-1", { type: "words,chars" }),
    chars = mySplitText.chars; //an array of all the divs that wrap each character
    words = mySplitText.words; //an array of all the divs that wrap each character

	document.querySelector("#hero-quote-1").classList.add('loaded');

  tl.from(words, {
    duration: 0.8,
    opacity: 0,
    y: 30,
    transformOrigin: "0% 50% -50",
    stagger: 0.01
  });

  var heroTl2 = gsap.timeline(),
    mySplitText = new SplitText("#hero-quote-2", { type: "words,chars" }),
    chars = mySplitText.chars, //an array of all the divs that wrap each character
    words = mySplitText.words; //an array of all the divs that wrap each character

	document.querySelector("#hero-quote-2").classList.add('loaded');

  heroTl2.from(words, {
    duration: 0.8,
    opacity: 0,
    y: 30,
    transformOrigin: "0% 50% -50",
        delay: 0.6,
    stagger: 0.01
  });

  var imageTl = gsap.timeline();

  imageTl.to('.image-swipe', {
    duration: 2,
    width: '0%',
    delay: .08,
    ease: 'power2.out'
  }).to('.media', {
    scale: 1,
    duration: 1.5,
    delay: -1.65,
    ease: 'power2.out'
  });


}
// Text animation helper function
function triggerTextAnimation (elem) {
  var tl = gsap.timeline({
    scrollTrigger: {
      trigger: elem,
      start: "center bottom"
    }
  });

  const splitLineChild = new SplitText(elem, { type: "lines", linesClass: "split-line-child" });
  const splitTextLines = new SplitText(elem, { type: "lines", linesClass: "split-line-wrap-++" });

  tl.staggerFrom(splitLineChild.lines, 1.2, {y: '100%', delay: 0, ease: 'power3.out'}, 0.15)

}


// GSAP Scroll Triggers
const scroll = () => {
    gsap.timeline({
        scrollTrigger: {
            start: 0,
            end: 'max',
            scrub: true
        }
    })
    .addLabel('start', 0)
    .to(DOM.sections.columns, {
        ease: 'none',
        startAt: {scale: 1.1},
        scale: 1,
    }, 'start')
    .to(DOM.sections.columns, {
        scrollTrigger: {
            trigger: DOM.sections.showcase,
            start: 0,
            end: 'top top',
            scrub: true
        },
        ease: 'power4.inOut',
        startAt: {
            opacity: 0.2,
        },
        opacity: 1,
        // repeat once (go back to "startAt" values)
        yoyo: true,
        repeat: 1
    }, 'start')
    .to(DOM.columnWraps, {
        ease: 'none',
        yPercent: pos => pos%2 ? 3 : -3
    }, 'start')

    gsap.utils.toArray('.animated-border').forEach((elem, i) => {
      let lineTl = gsap.timeline({
        scrollTrigger: {
          trigger: elem,
          start: "top bottom",
        }
      });
      lineTl.fromTo(elem, 1.5, {scaleX: 0}, {scale: 1 });
    });




  ScrollTrigger.matchMedia({

    // desktop - trigger on enter
    "(min-width: 750px)": function() {
      gsap.utils.toArray('.featured__image--sm').forEach((elem, i) => {
        let featuredSmallTl = gsap.timeline({
          scrollTrigger: {
             trigger: elem,
             start: "top bottom",
             end: 'bottom top',
             scrub: true
          }
        });
        featuredSmallTl.fromTo(elem, 3, {y: 100}, {y: -100 });
      });

    },

    // mobile
    "(max-width: 749px)": function() {
      gsap.utils.toArray('.featured__image--sm').forEach((elem, i) => {
        let featuredSmallTl = gsap.timeline({
          scrollTrigger: {
             trigger: elem,
             start: "top bottom",
             end: 'bottom top',
             scrub: true
          }
        });
        featuredSmallTl.fromTo(elem, 3, {y: 30}, {y: -100 });
      });
    }

  });

    gsap.utils.toArray('.featured__image--lg img').forEach((elem, i) => {
      let featuredLargeTl = gsap.timeline({
        scrollTrigger: {

           trigger: elem,
           start: "top 70%",
           end: 'bottom top',
           scrub: true
        }
      });
      featuredLargeTl.fromTo(elem, 3, {scale: 1}, {scale: 1.08, z:0.01 });
    });

    const maskedLines = document.querySelectorAll('[data-reveal="lines-masked"]');
    for (var i = 0; i < maskedLines.length; i++) {
      maskedLines[i].classList.add('loaded');
    }

    gsap.utils.toArray('[data-reveal="lines-masked"]').forEach((elem, i) => {
      triggerTextAnimation(elem);
    });


};
// Preload fonts
const preloadFonts = () => {
    return new Promise((resolve, reject) => {
        WebFont.load({
          custom: {
            families: ['Plaid', 'Plaid Mono'],
          },
          active: resolve
        });
    });
};

Promise.all([preloadImages('.column__item-img, .hero-portrait'), preloadFonts()]).then(() => {

    document.body.classList.remove('loading');
      // Lenis (smooth scrolling)
      initSmoothScrolling();
      // GSAP Scroll Triggers
      [...document.querySelectorAll('svg.svgtext')].forEach(el => new TextOnPath(el));
      scroll();
      heroAnimation();
    // Remove loader (loading class)

    // initialize custom cursor
    const cursor = new Cursor(document.querySelector('.cursor'));

    // mouse effects on all links and others
    [...document.querySelectorAll('a')].forEach(link => {
        link.addEventListener('mouseenter', () => cursor.enter());
        link.addEventListener('mouseleave', () => cursor.leave());
    });
    
    [...document.querySelectorAll('.header__link')].forEach(link => {
        link.addEventListener('click', () => {
          lenis.scrollTo(link.getAttribute("href"))
        });
    });

    

    // preload the images set as data attrs in the menu items
    preloader('.menu__item').then(() => {
        // initialize menu
        new Menu(menuEl);
    });

});
